import { axiosInstance } from "../utils/axiosInterceptor";

export const GetEventListService = async (data) => {
    return await axiosInstance.post('get_event_list', data);
}

export const CreateEventService = async (data) => {
    return await axiosInstance.post('add_event', data);
}   

export const UpdateEventService = async (data) => {
    return await axiosInstance.post('update_event', data);
}

export const GetEventDetailService = async (data) => {
    return await axiosInstance.post('get_event_by_id', data);
}

export const UpdateGalleryBtnStatusService = async (data) => {
    return await axiosInstance.post('update_gallery_btn_status', data);
}

export const GetEventUsersService = async (data) => {
    return await axiosInstance.post('user_list', data);
}

export const GetEventPhotosService = async (data) => {
    return await axiosInstance.post('photo_list', data);
}

export const AssignOrganizerService = async (data) => {
    return await axiosInstance.post('assign_event_to_user', data);
}

export const ImportFromDriveService = async (data) => {
    return await axiosInstance.post('add-drive-folder', data);
}

export const checkHashTagService = async (data) => {
    return await axiosInstance.post('check_event_hastag', data);
}

export const AddWhatsappNumberService = async (data) =>{
    return await axiosInstance.post('add_whatsapp_number', data);
}

export const WhatsappNumberListService = async (data) =>{
    return await axiosInstance.post('whatsapp_number_list', data);
}

export const ToggleImageVisibilityService = async (data) => {
    return await axiosInstance.post('move_to_gallery', data);
}

export const GetImageReport = async (data) => {
    return await axiosInstance.post('image_report_list', data);
}