import React, { useEffect, useState } from 'react'
import { CreateOrganizerService, GetOrganizersListService } from '../../../apis/organizers';
import { BreadCrumbs, Label, Modal, Table } from '../../../components/ui';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Input, Space } from 'antd';
import { tostE, tostS } from '../../../config/toastConfig';

export default function OrganizersPage() {
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const getOrganizers = async () => {
    setLoading(true);
    try {
      const res = await GetOrganizersListService();
      if (res.status === 200 && res.data.success) {
        console.log(res.data.organizers_list);
        setData(res.data.organizers_list);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrganizers();
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',    
    },
  ];

  const OrganizerSchema = Yup.object().shape({
    mobile_number: Yup.string().required("Mobile number is required").min(10, "Mobile number must be 10 digit"),
  });

  const formik = useFormik({
    initialValues: {
      code: "+91",
      mobile_number: ""
    },
    validationSchema: OrganizerSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {

      let body = {...values};
      try {
        let res = await CreateOrganizerService(body);
        if (res.status === 200 && res.data.success) {
          tostS(res.data.message);
          setShowModal(false);
          getOrganizers();
          resetForm();
        }else {
          tostE(res.data.message);
          setShowModal(false);
          resetForm();
        }
      } catch (error) {
        tostE(error.response.data.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const { values, errors, touched, getFieldProps, handleSubmit, resetForm, isSubmitting } = formik;


  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <BreadCrumbs
          links={[
            { name: 'Home', link: '/app/dashboard', icon: 'fa-house' },
            { name: 'Organizers', link: '/app/organizers', icon: 'fa-users' },
          ]}
        />
        <Button type='primary' onClick={() => setShowModal(true)}>Add Organizer</Button>
      </div>
      <div className='main-content'>
        <div className='card custom-card'>
          <Table 
            loading={loading}
            data={data}
            columns={columns}
          />
        </div>
      </div>
      <Modal
        modalTitle={"Add Organizer"}
        open={showModal}
        confirmLoading={isSubmitting}
        handleConfirm={() => {
          handleSubmit();
        }}
        handleCancel={() => {
          resetForm();
          setShowModal(false);
        }}
        okText={"Add Organizer"}
      >
        <FormikProvider value={formik}>
          <Form layout="vertical" onFinish={handleSubmit}>
            <Label
              label='Mobile Number'
              error={touched.mobile_number && errors.mobile_number}
              helperText={touched.mobile_number && errors.mobile_number ? errors.mobile_number : ''}
              required={true}
            >
              <Space.Compact>
                <Input
                  placeholder="Code"
                  {...getFieldProps('code')}
                  value={values.code}
                  disabled 
                  style={{
                    width: '20%',
                  }}
                />
                <Input
                  placeholder="Enter Mobile Number"
                  {...getFieldProps('mobile_number')}
                  style={{
                    width: '80%',
                  }}
                />
              </Space.Compact>
            </Label>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  )
}
