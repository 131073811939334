const UiRender = ({color, text, icon}) =>{
    return (
        <span style={{color: color}} className="status-render">
            {icon && <i className={"mr-2 fa-solid " + icon}></i>}
            <p>{text}</p> 
        </span>
    )
}

export const EventStatusRender = ({value}) => {
    switch (value.toString().toLowerCase()) {
        case "past":
            return <UiRender color={'#FF0000'} text={"Past"} icon={'fa-circle'}/>
        case "upcoming":
            return <UiRender color={'#f5e642'} text={"Upcoming"} icon={'fa-circle'}/>
        case "ongoing":
            return <UiRender color={'#37A353'} text={"Ongoing"} icon={'fa-circle'}/>
        default:
            return <UiRender color={'#f5e642'} text={"Upcoming"} icon={'fa-circle'}/>
    }
}