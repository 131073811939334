import React, { useEffect, useState } from 'react'
import { Modal, Table } from '../../../../components/ui'
import { useParams } from 'react-router-dom';
import { GetEventPhotosService, GetImageReport, ToggleImageVisibilityService } from '../../../../apis/events';
import { Badge, Button, Image, Switch } from 'antd';
import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';

export default function EventPhotos() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [view, SetView] = useState('List');
    const [loading, setLoading] = useState(false);
    const [visibilityLoading, setVisibilityLoading] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [issuesList, setIssuesList] = useState({
        loading: false,
        show: false,
        data: []
    });


    const issueColumns = [
        {
            title: 'Issue Description',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Reported By',
            key: 'reported_by',
            render: (text, record) => (
                <span>{record.user_detail.mobile_number}</span>
            )
        }
    ]

    const getEventPhotos = async () => {
        setLoading(true);
        try {
            const res = await GetEventPhotosService({ event_id: id });
            if (res.status === 200 && res.data.success) {
                const ls = res.data.photo_list.map((item) => ({ ...item, key: item.id }));
                setData(ls);
                setSelectedRowKeys([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getEventPhotos();
    }, []) // eslint-disable-line

    const handleChangeGalleryVisibility = async (checked, id, type) => {
        setVisibilityLoading(id);
        try {
            const body = {
                image_ids: type === "single" ? [id] : selectedRowKeys,
                status: checked ? 1 : 0
            }

            const response = await ToggleImageVisibilityService(body);
            if (response.status === 200 && response.data.success) {
                getEventPhotos();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setVisibilityLoading(null);
        }
    }

    const handleGetPhotoIssues = async (data) => {
        setIssuesList({
            show: true,
            data: data.image_report
        });
    }

    const columns = [
        {
            title: 'Photo',
            dataIndex: 'image_url',
            key: 'name',
            render: (text, record) => (
                <Badge count={record.image_report.length ? <WarningOutlined style={{ color: "red" }} />: 0}>
                    <Image width={80} height={80} src={text} fallback='https://via.placeholder.com/150'  />
                    {/* <Avatar shape="square" size={64} src={text} /> */}
                </Badge>
            )
        },
        {
            title: 'Uploaded By',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: "Show in Gallery",
            dataIndex: 'is_in_gallery',
            key: 'is_in_gallery',
            render: (text, record) => (
                <>
                    <Switch
                        size="small"
                        loading={visibilityLoading && visibilityLoading === record.id}
                        checked={text === 1}
                        onChange={(checked) => {
                            handleChangeGalleryVisibility(checked, record.id, "single");
                        }}
                        disabled={visibilityLoading && visibilityLoading !== null}
                    />
                </>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    {record.image_report.length > 0 &&
                    <Button
                        icon={<ExclamationCircleOutlined />}
                        title="View Issues"
                        onClick={() => { handleGetPhotoIssues(record) }}
                    />}
                </>
            )
        },

    ]

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
        <>
            <div className='d-flex justify-content-end align-items-center mb-3'>
                <div>
                    <Button onClick={() => { SetView("List") }} title='List View' shape="square" icon={<i className="fa-solid fa-list" />} style={{ marginRight: 10 }} />
                    <Button onClick={() => { SetView("Grid") }} title='Grid View' shape="square" icon={<i className="fa-solid fa-grip" />} />
                </div>
            </div>
            {hasSelected ? 
            <>
                <p className='mb-2'>
                    Selected {selectedRowKeys.length} items of {data.length} items | {" "}
                    {data.length === selectedRowKeys.length ?
                    <span className='text-primary cursor-pointer' onClick={() => { setSelectedRowKeys([]) }}>Clear Selection</span> 
                    :<span 
                        className='text-primary cursor-pointer' 
                        onClick={() => {
                        setSelectedRowKeys(data.map((item) => item.id))
                        }}
                    >
                        Select all {data.length} items
                    </span>} 
                </p> 
                <Button type="primary" className='me-2' onClick={() => {handleChangeGalleryVisibility(false, selectedRowKeys, "multiple") }} disabled={!hasSelected} loading={loading}>
                    Hide from galley
                </Button>
                <Button type="primary" onClick={() => {handleChangeGalleryVisibility(true, selectedRowKeys, "multiple") }} disabled={!hasSelected} loading={loading}>
                    Show in galley
                </Button>
            </>
            : null}
            {view === "List" &&
                <Table
                    columns={columns}
                    data={data}
                    loading={loading}
                    rowSelection={rowSelection}
                />}
            {view === "Grid" &&
                <>
                    <div className='row'>
                        {data.map((item, index) => (
                            <div className='col-lg-2 col-md-3 col-xs-3 p-1' key={index}>
                                <div className='grid-item card custom-card p-0' style={{ alignItems:'center', display: 'flex', justifyContent: 'center' }}>
                                <Image style={{ objectFit: 'contain' }} height={"100%"} width={'100%'} src={item.image_url} fallback='https://via.placeholder.com/150' />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
            <Modal
                open={issuesList.show}
                title="Issues"
                onCancel={() => {
                    setIssuesList({
                        show: false,
                        data: []
                    });
                }}
                hideFooter
            >
                <Table
                    columns={issueColumns}
                    data={issuesList.data}
                    loading={issuesList.loading}
                />
            </Modal>
        </>
    )
}
