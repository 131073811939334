import React, { useEffect, useState } from 'react'
import { BreadCrumbs, DatePickerInput, Label, Modal } from '../../../components/ui';
import { GetEventListService, CreateEventService, checkHashTagService } from '../../../apis/events';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Input, Pagination, Select } from 'antd';
import dayjs from 'dayjs';
import { EVENT_NAME_REGEX } from '../../../config/constant';
import { useNavigate } from 'react-router-dom';
import EventListSkeleton from '../../../components/common/EventListSkeleton';
import { EventCard, NoEventComponent } from '../../../components/event';
import { tostE, tostS } from '../../../config/toastConfig';


export default function EventListing() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState('');
  const [hashAvailable, setHashAvailable] = useState(null);
  const [hashTerm, setHashTerm] = useState('');
  const [checking, setChecking] = useState(false);

  const getEvents = async () => {
    setLoading(true);
    try {
      const body = { page };
      if(statusFilter && statusFilter!==''){
        body.status = statusFilter;
      }
      const res = await GetEventListService(body);
      if (res.status === 200 && res.data.success) {
        setData(res.data.event_list.data);
        setTotal(res.data.event_list.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEvents();
  }, [page, statusFilter]); // eslint-disable-line

  const CreateEventSchema = Yup.object().shape({
    event_name: Yup.string().required("Event name is required"),
    hastag: Yup.string().required("Hashtag is required").matches(EVENT_NAME_REGEX, "Hashtag is invalid"),
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date().required("End date is required"),
  });

  const formik = useFormik({
    initialValues: {
      event_name: "",
      hastag: "",
      start_date: "",
      end_date: "",
      // gallery_btn: true
    },
    validationSchema: CreateEventSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if(!hashAvailable){
        return;
      }
      let body = {
        event_title: values.event_name,
        hastag: values.hastag,
        start_date: (values.start_date).format('YYYY-MM-DD'),
        end_date: (values.end_date).format('YYYY-MM-DD')
      }
      try {
        let res = await CreateEventService(body);
        if (res.status === 200 && res.data.success) {
          tostS(res.data.message);
          setShowModal(false);
          getEvents();
          resetForm();
        }
      } catch (error) {
        tostE(error.response.data.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const { values, setValues, errors, touched, getFieldProps, setFieldValue, handleSubmit, resetForm, isSubmitting } = formik;

  useEffect(() => {
    const timer = setTimeout(async () => {
      if(hashTerm === '') return;
      setChecking(true);
      try{
        const checkHash = await checkHashTagService({ hastag: hashTerm });
        if(checkHash.status === 200 && !checkHash.data.success){
          setHashAvailable(false);
          return
        } else{
          setHashAvailable(true);
          setFieldValue("hastag", hashTerm)
          return
        }
      }catch(error){
        console.log(error);
      }finally{
        setChecking(false);
      }

    }, 1000);
    return () => {
        clearTimeout(timer);
    }
}, [hashTerm]) // eslint-disable-line


  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <BreadCrumbs
          links={[
            { name: 'Home', link: '/app/dashboard', icon: 'fa-house' },
            { name: 'Events', link: '/app/events', icon: 'fa-calendar' },
          ]}
        />
        <div className='d-flex flex-wrap '>
          <Select 
            title="Status Filter"
            options={[
              {
                value: '',
                label: 'All',
                selected: true
              },
              {
                value: 'Past',
                label: 'Past',
              },
              {
                value: 'Upcoming',
                label: 'Upcoming',
              },
              {
                value: 'Ongoing',
                label: 'Ongoing',
              }
            ]}
            value={statusFilter}
            style={{ width: 120, marginRight: 10 }}
            onChange={(value) => {setStatusFilter(value)}}
            placeholder="Status"
          />
          <Button type='primary' onClick={() => setShowModal(true)} title="Create Event">
            <i class="fa-solid fa-plus"></i>
            Create Event
          </Button>
        </div>
      </div>
      <div className='main-content'>
        {!loading ? ((data.length) > 0 ? 
        <>
        {data.map((item, index) => (
          <EventCard 
            key={index} 
            data={item} 
            manageEvent={(data) => {navigate(`/app/events/manage/${data.id}/overview`)}}
          />
        ))} 
        <Pagination 
          current={page} 
          total={total} 
          align='end'
          hideOnSinglePage
          responsive
          onChange={(page) => setPage(page)}
        />
        </>
        :(<>
          <NoEventComponent />
        </>)) : (<EventListSkeleton />)}
      </div>
      <Modal
        modalTitle={"Create Event"}
        open={showModal}
        confirmLoading={isSubmitting}
        handleConfirm={() => {
          handleSubmit();
        }}
        handleCancel={() => {
          resetForm();
          setShowModal(false);
        }}
        okText={"Create Event"}
      >
        <FormikProvider value={formik}>
          <Form layout="vertical" onFinish={handleSubmit}>
            <Label
              label='Event Name'
              error={touched.event_name && errors.event_name}
              helperText={touched.event_name && errors.event_name ? errors.event_name : ''}
              required={true}
            >
              <Input
                placeholder="Enter event name"
                {...getFieldProps('event_name')}
              />
            </Label>
            <Label
              label='Hashtag'
              error={touched.hastag && errors.hastag}
              helperText={touched.hastag && errors.hastag ? errors.hastag : ''}
              required={true}
            >
              <Input
                addonBefore={"#"}
                placeholder="Enter hashtag"
                name="hashtag"
                value={hashTerm}
                onChange={(e) => {setHashTerm(e.target.value)}}
              />
              {(hashTerm !=='' && !checking && hashAvailable !== null) && <>
              {hashAvailable ? <p className='text-success'>You made a great choice</p> 
              : <p className='text-danger'>Sorry! This hashtag is already taken</p>}
              </>}
            </Label>
            <Label
              label='Start Date'
              error={touched.start_date && errors.start_date}
              helperText={touched.start_date && errors.start_date ? errors.start_date : ''}
              required={true}
            >
              <DatePickerInput
                placeholder="Select Start Date"
                showNow={false}
                value={values.start_date}
                onChange={(date) => {
                  setValues({ ...values, start_date: date })
                }}
                minDate={dayjs(new Date())}
                fullWidth={true}
              />
            </Label>
            <Label
              label='End Date'
              error={touched.end_date && errors.end_date}
              helperText={touched.end_date && errors.end_date ? errors.end_date : ''}
              required={true}
            >
              <DatePickerInput
                placeholder="Select End Date"
                showNow={false}
                value={values.end_date}
                onChange={(date) => {
                  setValues({ ...values, end_date: date })
                }}
                minDate={dayjs(values.start_date)}
                fullWidth={true}
                disabled={values.start_date ? false : true}
              />
            </Label>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  )
}
