import React, { useEffect, useState } from 'react'
import { Table } from '../../../../components/ui'
import { useParams } from 'react-router-dom';
import { GetImageReport } from '../../../../apis/events';
import { Avatar } from 'antd';

export default function EventImageReports() {
  const {id} = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await GetImageReport({id});
      if(res.status === 200 && res.data.success){
        setData(res.data.report_list);
      }
    })()

  }, []); // eslint-disable-line

  const columns = [
    {
      title: "Image",
      key: "image_id",
      render: (text, record) => (
        <Avatar shape='square' size={64} src={record.image_detail.image_url} alt='image' />
      )
    },
    {
      title: 'Issue Description',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Reported By',
      key: 'reported_by',
      render: (text, record) => (
        <span>{record.user_detail.mobile_number}</span>
      )
    }
  ]


  return (
    <div>
      <Table
        columns={columns}
        data={data}
      />

    </div>
  )
}
