import React, { useEffect, useState } from 'react'
import { Label, Modal, Table } from '../../../../components/ui';
import { Form, Input, Space } from 'antd';
import { tostE, tostS } from '../../../../config/toastConfig';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { AddWhatsappNumberService, WhatsappNumberListService } from '../../../../apis/events';
import { useParams } from 'react-router-dom';

export default function WhatsappNumberSection() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const GetListData = async () => {
        setLoading(true);
        try {
            const res = await WhatsappNumberListService({ event_id: id });
            if (res.status === 200 && res.data.success) {
                setListData(res.data.number_list);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }


    useEffect(()=>{
        GetListData();
    },[]); // eslint-disable-line


    const columns = [
        {
            title: 'Mobile',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        },
    ];


    const OrganizerSchema = Yup.object().shape({
        mobile_number: Yup.string().required("Mobile number is required").min(10, "Mobile number must be 10 digit"),
    });

    const formik = useFormik({
        initialValues: {
            code: "+91",
            mobile_number: ""
        },
        validationSchema: OrganizerSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            let body = { ...values, event_id: id };
            try {
                let res = await AddWhatsappNumberService(body);
                if (res.status === 200 && res.data.success) {
                    tostS(res.data.message);
                    setShowModal(false);
                    resetForm();
                } else {
                    tostE(res.data.message);
                    setShowModal(false);
                    resetForm();
                }
            } catch (error) {
                tostE(error.response.data.message);
            } finally {
                setSubmitting(false);
            }
        }
    });

    const { values, errors, touched, getFieldProps, handleSubmit, resetForm, isSubmitting } = formik;


    return (
        <>
            <div className='host-details mb-3'>
                <h5 className='m-0 text-black'>Other Whatsapp numbers</h5>
                <p>Update other whatsapp numbers</p>
                <div className='d-flex align-items-center mt-3'>
                    <Input readOnly className='w-auto' placeholder='Other whatsapp number' onClick={() => { setShowModal(true); }} addonAfter={<i onClick={() => { setShowModal(true); }} className='fa-solid fa-pencil' />} />
                </div>
            </div>
            <div>
                <Table
                    loading={loading}
                    data={listData}
                    columns={columns}
                />
            </div>

            <Modal
                open={showModal}
                onCancel={() => { 
                    setShowModal(false); 
                    resetForm();
                }}
                onOk={() => { handleSubmit(); }}
                title="Add Whatsapp Number"
                okText="Add"
                confirmLoading={isSubmitting}
            >
                <FormikProvider value={formik}>
                    <Form layout="vertical" onFinish={handleSubmit}>
                        <Label
                            label='Mobile Number'
                            error={touched.mobile_number && errors.mobile_number}
                            helperText={touched.mobile_number && errors.mobile_number ? errors.mobile_number : ''}
                            required={true}
                        >
                            <Space.Compact>
                                <Input
                                    placeholder="Code"
                                    {...getFieldProps('code')}
                                    value={values.code}
                                    disabled
                                    style={{
                                        width: '20%',
                                    }}
                                />
                                <Input
                                    placeholder="Enter Mobile Number"
                                    {...getFieldProps('mobile_number')}
                                    style={{
                                        width: '80%',
                                    }}
                                />
                            </Space.Compact>
                        </Label>
                    </Form>
                </FormikProvider>
            </Modal>
        </>
    )
}
