// export const API_BASE_URL = "https://apis.hellopicbot.com/api/"
// export const API_BASE_URL = "https://apis.hellopicbot.com/getgabs/api/"
// export const API_BASE_URL = "http://192.168.40.30/hellopicbot/api/"
export const API_BASE_URL = "https://demo.vehub.live/whatsapp_rekog/api/"

export const FORM_DATE_FORMAT = "MMM DD, YY"
export const FORM_TIME_FORMAT = "HH:mm A"
export const FORM_DATE_TIME_FORMAT = "MMM DD, YY HH:mm A"

export const EVENT_NAME_REGEX = /^[a-zA-Z0-9]+$/;
export const HASTAG_REGEX = /^#([a-zA-Z0-9]+)$/;

export const GOOGLE_DRIVE_LINK_REGEX = /(?:https?:\/\/)?drive\.google\.com\/drive\/folders\/([a-zA-Z0-9_-]+)(\?.*)?/;